import React, { useContext, useState, useEffect } from 'react'
import Collapsible from 'react-collapsible'
import gql from 'graphql-tag'
import { graphql, withApollo, compose } from 'react-apollo'

import Checkout from '../../components/Checkout'
import * as Grid from '../../components/Grid'
import { NavButton, Button } from '../../components/Buttons'
import ProductSummary from '../../components/Checkout/components/ProductSummary'
import TitleSummary from '../../components/Checkout/components/TitleSummary'
import DateDelivery from '../../components/Checkout/components/DateDelivery'
import Icon from '../../components/Icon'
import p2pImage from '../../assets/p2p/p2p.svg'
import { ContainerElements } from '../../components/Global/style'
import Loader from '../../components/Loader'

import { useIsMobile, useIsIpad } from '../../helpers/resize'
import TextDelivery from '../../helpers/dateDelivery'
import ResumeData from '../../helpers/resumeData'

import ThemeContext from '../../context/ThemeContext'
import CartContext from '../../context/CartContext'
import PaymentResponseContext from '../../context/PaymentResponseContext'
import LoaderContext from '../../context/LoaderContext'
import Customer from '../../model/Customer'
import Address from '../../model/Address'

import { PaymentContainer, Card, HeaderCard, LinkResponsive, BodyCard, TitleCard, TitleBody, TermsContainer, Checkbox, ContainerTransaction, ContainerLink } from './style'
import TitleSummaryResponsive from '../../components/Checkout/components/TitleSummaryResponsive'
import { COLORS } from '../../styles/colors'
import { GtmEventsLoad } from '../../helpers/gtm'
import { PurchaseEvent } from '../../helpers/GTM/PurchaseEvent'

type PaymentInfo = {
  paymentStatus: string
  requestId: string
  signature: string
  orderId: string
  p2pReference: string
}

const ADD_TRACKING_MUTATION = gql`
  mutation AddTrack(
    $token: String!
    $createdAt: String!
    $createdAtUnix: Int!
    $type: String!
    $eventType: String!
    $email: String
    $phoneNumber: String
    $document: String
    $docUpdated: Int!
    $paymentResponse: String
  ) {
    addTrack(
      token: $token
      createdAt: $createdAt
      createdAtUnix: $createdAtUnix
      type: $type
      eventType: $eventType
      email: $email
      phoneNumber: $phoneNumber
      document: $document
      paymentResponse: $paymentResponse
      docUpdated: $docUpdated
    ) {
      token
    }
  }
`

const GENERATE_ORDER_MUTATION = gql`
  mutation generateOrder($input: CreateOrderInput!) {
    generateOrder(input: $input) {
      id
      status
      provider_payment_code
    }
  }
`

const GET_ORDER_STATUS = gql`
  query getOrderStatus($orderId: String!) {
    OrderStatus(orderId: $orderId) {
      status
    }
  }
`

const GET_CHECK_REQUEST = gql`
  query getP2PStatus($requestId: String!) {
    P2PStatus(requestId: $requestId) {
      payment {
        status {
          status
        }
      }
    }
  }
`

const GENEREATE_REQUEST_PAYMENT = gql`
  mutation requestPayment($input: RequestOrderInput!) {
    requestPayment(input: $input) {
      processUrl
      Message
    }
  }
`

let timer: number = 0
declare const P: any

function Payment({ history, mutate, client }: any) {
  const storageScheduled = JSON.parse(
    window.localStorage.getItem('scheduled') || '{}',
  )
  const storageSpecifications = JSON.parse(window.localStorage.getItem('specifications') || '{}')
  const { setResponse } = useContext(PaymentResponseContext)
  const { onSetLoaded, loaded, updateItems, onDelAllItems, forceShowNotification } = useContext(CartContext)
  const { setLoading } = useContext(LoaderContext)
  const initialMql = window.matchMedia(`(max-width: 767px)`)
  const isMobile = useIsMobile(initialMql.matches)

  const initialMqlIpad = window.matchMedia(`(max-width: 1024px)`)
  const isIpad = useIsIpad(initialMqlIpad.matches)

  const { primary, secondary } = useContext(ThemeContext)
  const [termsState, setTerms] = useState(false)

  const { cityName, city, province, provinceName, street1, street2, number, reference, firstName, lastName, phone, email }: Address = JSON.parse(
    window.localStorage.getItem('address') || '{}',
  )

  const {
    identificationType,
    identificationTypeName,
    identificationNumber,
    firstName: firstNameCustomer,
    lastName: lastNameCustomer,
    businessName: businessNameCustomer,
    mobile: mobileCustomer,
    address: addressCustomer,
    email: emailCustomer,
    coupon: coupon,
  }: Customer = JSON.parse(window.localStorage.getItem('customer') || '{}')

  const itemsCart = JSON.parse(window.localStorage.getItem('items') || '{}')
  const enCasa = JSON.parse(window.localStorage.getItem('encasa') || '{}')

  const handleClickOnNext = (phoneNumber: any, email: any, document: any, paymentResponse: string) => {
    var token = window.localStorage.getItem('token') || ''
    mutate({
      mutation: ADD_TRACKING_MUTATION,
      variables: {
        token: token,
        createdAt: new Date().toLocaleString(),
        createdAtUnix: Math.round(+new Date() / 1000),
        type: 'PAYMENT_EVENT',
        eventType: 'onClick',
        email: email,
        phoneNumber: phoneNumber,
        document: document,
        paymentResponse: paymentResponse,
        docUpdated: 1,
      },
    })
  }

  const generateOrder = async () => {
    if (termsState == true) {
      setLoading && setLoading({ loading: true })

      const items: any[] = []
      itemsCart.forEach((item: any) => {
        for (let i = 0; i < item.quantity; i++) {
          items.push({ variationId: item.id })
        }
      })

      if (enCasa && enCasa.added && enCasa.variation && enCasa.variation.id && enCasa.variation.id !== '') {
        items.push({ variationId: enCasa.variation.id })
      }

      let order = {
        address: {
          state: province,
          city: city,
          zone: '',
          reference: reference,
          street1: street1,
          street2: street2,
          number: number,
          email: email,
          customerName: `${firstName} ${lastName}`,
          phone: phone,
        },
        customer: {
          firstName: firstNameCustomer,
          lastName: lastNameCustomer,
          identificationNumber: identificationNumber,
          identificationType: identificationType,
          mobile: mobileCustomer,
          email: emailCustomer,
          address: addressCustomer,
          businessName: businessNameCustomer,
          coupon: coupon,
        },
        lineItems: items,
        metaData: {
          isProgramed: (storageScheduled && storageScheduled.scheduled),
          dateProgramed: storageScheduled.date || null,
          comments: window.localStorage.getItem('user_comments') || '',
          diners: String(storageSpecifications.diners || 1),
          validation_identification: window.localStorage.getItem('identification') || '',
        },
      }

      const orderResult = await mutate({
        mutation: GENERATE_ORDER_MUTATION,
        variables: {
          input: order,
        },
      })

      const { generateOrder } = orderResult.data
      window.localStorage.setItem('orderId', generateOrder.id)
      GtmEventsLoad(new PurchaseEvent('purchase', 'order', 'purchase').initialize(itemsCart, generateOrder.id))
      timer = window.setInterval(
        () =>{
          getOrderStatus(
            generateOrder.id,
            generateOrder.provider_payment_code || '324242',
          )},
        4000,
      )

      return
    }

    forceShowNotification &&
      forceShowNotification({
        type: 'alert',
        message: 'Para avanzar, debe aceptar los terminos y condiciones',
      })
  }

  const getOrderStatus = async (order_id: string, PAYMENT_PROVIDER_CODE: string) => {
    const { data } = await client.query({
      query: GET_ORDER_STATUS,
      variables: {
        orderId: order_id,
      },
      fetchPolicy: 'network-only',
    })

    status = data.OrderStatus.status
    if (status == 'approved') {
      window.clearInterval(timer)
      generatePaymentRequest(order_id, PAYMENT_PROVIDER_CODE)
    }
  }

  const generatePaymentRequest = async (order_id: string, PAYMENT_PROVIDER_CODE: string) => {
    const order = {
      orderId: order_id,
      paymentProviderCode: PAYMENT_PROVIDER_CODE,
    }
    const p2pLink = await mutate({
      mutation: GENEREATE_REQUEST_PAYMENT,
      variables: {
        input: order,
      },
    })

    const { Message, processUrl } = p2pLink.data.requestPayment
    const isCouponApplied = Message && Message === 'Coupon applied for current order'

    if (isCouponApplied) {
      const orderId = window.localStorage.getItem('orderId') || ''
      setLoading && setLoading({ loading: false })
      processPayment({
        paymentStatus: 'APPROVED',
        requestId: '',
        signature: '',
        orderId,
        p2pReference: '',
      })
      return
    }

    window.localStorage.setItem('paymentURL', processUrl)
    setLoading && setLoading({ loading: false })
    P.init(processUrl)
  }

  const getCheckRequest = async (status: string, requestId: string, signature: string, p2pReference: string) => {
    const { data, error } = await client.query({
      query: GET_CHECK_REQUEST,
      variables: {
        requestId: requestId,
      },
      fetchPolicy: 'network-only',
    })

    if (error) {
      setLoading && setLoading({ loading: false })
      forceShowNotification &&
        forceShowNotification({
          type: 'alert',
          message: 'ERROR, INTENTELO DE NUEVO.',
        })
    }

    const payment = data.P2PStatus.payment
    setLoading && setLoading({ loading: false })

    const orderId = window.localStorage.getItem('orderId') || ''

    if (payment) {
      const paymentStatus = payment[0].status.status
      processPayment({
        paymentStatus,
        requestId,
        signature,
        orderId,
        p2pReference,
      })
      return
    }

    if (status == 'PENDING') {
      GtmEventsLoad(new PurchaseEvent('pending', 'order_pending', 'pending').initialize(itemsCart, p2pReference))
      processPayment({
        paymentStatus: status,
        requestId,
        signature,
        orderId,
        p2pReference,
      })
      return
    }

    if (status == 'REJECTED') {
      GtmEventsLoad(new PurchaseEvent('rejected', 'order_rejected', 'rejected').initialize(itemsCart, p2pReference))
      processRejectedPayment()
    }
  }

  const processPayment = (paymentInfo: PaymentInfo) => {
    const { paymentStatus, requestId, signature, orderId, p2pReference } = paymentInfo

    const responseInfo = ResumeData(paymentStatus, requestId, signature, TextDelivery, emailCustomer, orderId, p2pReference)

    switch (paymentStatus) {
      case 'PENDING':
      case 'MANUAL':
      case 'APPROVED':
        GtmEventsLoad(new PurchaseEvent('approved', 'order_approved', 'approved').initialize(itemsCart, p2pReference))
        onDelAllItems && onDelAllItems()
        window.localStorage.removeItem('coupon')
        window.localStorage.removeItem('encasa')
    }

    setResponse && setResponse(responseInfo)

    window.localStorage.setItem('response', JSON.stringify(responseInfo))

    handleClickOnNext(mobileCustomer, emailCustomer, identificationNumber, status)
    history.push('/resume')
  }

  const processRejectedPayment = () => {
    forceShowNotification &&
      forceShowNotification({
        type: 'alert',
        message: 'Pago Cancelado!',
      })
    handleClickOnNext(mobileCustomer, emailCustomer, identificationNumber, 'CanceledPayment')
  }
  const [mcn, setMcn] = useState(false)

  useEffect(() => {
    !loaded &&
      updateItems &&
      updateItems().then((ok: boolean) => {
        onSetLoaded && onSetLoaded(ok)
      })
  })

  useEffect(() => {
    const strmcn =window.sessionStorage.getItem("mcn");
    if (strmcn === "1") {
      setMcn(true);
    }
    try {
      P.on('response', (o: any) => {
        setLoading && setLoading({ loading: true })
        const status = o.status.status
        const requestId = o.requestId
        const signature = o.signature
        const p2pReference = o.reference
        const orderId = window.localStorage.getItem('orderId') || ''
        if (orderId) {
          getCheckRequest(status, requestId.toString(), signature, p2pReference)
        }
      })
    } catch (error) {
      console.error(error)
    }
  }, [])

  return loaded ? (
    <Checkout>
      <Grid.Row>
        {isMobile && (
          <Grid.Column>
            <ContainerElements>
              <DateDelivery mcn={mcn} />
            </ContainerElements>
          </Grid.Column>
        )}
        <Grid.Column md={isMobile || isIpad ? 12 : 9}>
          <div className="animated fadeInLeft">
            <h3>Resumen del Pedido</h3>
            <PaymentContainer>
              <Card>
                <HeaderCard>
                  <TitleCard>Dirección de envío</TitleCard>
                  <LinkResponsive to="/shipping">Editar</LinkResponsive>
                </HeaderCard>
                <BodyCard>
                  <div>
                    <TitleBody>Provincia:</TitleBody>
                    <span>{provinceName}</span>
                  </div>
                  <div>
                    <TitleBody>Ciudad:</TitleBody>
                    <span>{cityName}</span>
                  </div>
                  <div>
                    <TitleBody>Calle Principal:</TitleBody>
                    <span>{street1}</span>
                  </div>
                  <div>
                    <TitleBody>Calle Secundaria:</TitleBody>
                    <span>{street2}</span>
                  </div>
                  <div>
                    <TitleBody>Numeración:</TitleBody>
                    <span>{number}</span>
                  </div>
                  <div>
                    <TitleBody>Detalle de Vivienda:</TitleBody>
                    <span>{reference}</span>
                  </div>
                </BodyCard>
                <h4>Persona de Contacto</h4>
                <BodyCard>
                  <div>
                    <TitleBody>Nombre:</TitleBody>
                    <span>{firstName}</span>
                  </div>
                  <div>
                    <TitleBody>Apellido:</TitleBody>
                    <span>{lastName}</span>
                  </div>
                  <div>
                    <TitleBody>Teléfono de Contacto:</TitleBody>
                    <span>{phone}</span>
                  </div>
                  <div>
                    <TitleBody>Correo Electrónico:</TitleBody>
                    <span>{email}</span>
                  </div>
                </BodyCard>
              </Card>
              <Card>
                <HeaderCard>
                  <TitleCard>Datos del Cliente</TitleCard>
                  <LinkResponsive to="/billing" fontcolor={COLORS.primary}>
                    Editar
                  </LinkResponsive>
                </HeaderCard>
                <BodyCard>
                  <div>
                    <TitleBody>Tipo de Identificación:</TitleBody>
                    <span>{identificationTypeName}</span>
                  </div>
                  <div>
                    <TitleBody>Identificación:</TitleBody>
                    <span>{identificationNumber}</span>
                  </div>

                  {identificationType == 'RUC' ? (
                    <div>
                      <TitleBody>Razón Social:</TitleBody>
                      <span>{businessNameCustomer}</span>
                    </div>
                  ) : (
                    <>
                      <div>
                        <TitleBody>Nombre:</TitleBody>
                        <span>{firstNameCustomer}</span>
                      </div>
                      <div>
                        <TitleBody>Apellido:</TitleBody>
                        <span>{lastNameCustomer}</span>
                      </div>
                    </>
                  )}
                  <div>
                    <TitleBody>Teléfono Celular:</TitleBody>
                    <span>{mobileCustomer}</span>
                  </div>
                  <div>
                    <TitleBody>Dirección:</TitleBody>
                    <span>{addressCustomer}</span>
                  </div>
                  <div>
                    <TitleBody>Correo Electrónico:</TitleBody>
                    <span>{emailCustomer}</span>
                  </div>
                </BodyCard>
              </Card>
            </PaymentContainer>
          </div>
        </Grid.Column>

        {!isMobile && (
          <Grid.Column md={isIpad ? 6 : 3}>
            <TitleSummary />
            <ContainerElements>
              <ProductSummary />
            </ContainerElements>
            {!isIpad && (
              <>
                <ContainerElements>
                  <DateDelivery mcn={mcn} />
                </ContainerElements>
                <TermsContainer onClick={() => setTerms(!termsState)}>
                  <Checkbox onClick={() => setTerms(!termsState)}>{termsState == true ? <Icon color="black" size="11px" name="ok" className="kronos-icon" /> : <div />}</Checkbox>
                  <label onClick={() => setTerms(!termsState)}>
                    Acepto{' '}
                    <a target="_blank" href="https://grande-table.s3.amazonaws.com/tyc-grande-table-delivery.pdf">
                      <u>Términos y condiciones</u>
                    </a>
                  </label>
                </TermsContainer>
                <ContainerElements>
                  <Button
                    backgroundColor={primary}
                    color={secondary}
                    onClick={() => {
                      generateOrder()
                    }}
                    id={'MKTPL_PAY_NEXT'}
                  >
                    COMPRAR
                  </Button>
                </ContainerElements>
                <ContainerTransaction>
                  <label>Esta transacción se procesa con</label>
                  <ContainerLink href="https://www.placetopay.com/web/home" target="_blank">
                    <img src={p2pImage} />
                  </ContainerLink>
                </ContainerTransaction>

                <ContainerElements>
                  <NavButton
                    to="/billing"
                    backgroundColor={COLORS.teeth}
                    color={primary}
                    bordercolor={primary}
                    third={'#000000'}
                    onClick={() => {
                      onSetLoaded && onSetLoaded(false)
                    }}
                  >
                    Atrás
                  </NavButton>
                </ContainerElements>
              </>
            )}
          </Grid.Column>
        )}
        {!isMobile && isIpad && (
          <Grid.Column md={6} style={{ margin: '3.5rem 0 0' }}>
            <ContainerElements>
              <DateDelivery mcn={mcn} />
            </ContainerElements>
            <TermsContainer onClick={() => setTerms(!termsState)}>
              <Checkbox>{termsState == true ? <Icon color="black" size="11px" name="ok" className="kronos-icon" /> : <div />}</Checkbox>
              <label>
                Acepto{' '}
                <a target="_blank" href="https://grande-table.s3.amazonaws.com/tyc-grande-table-delivery.pdf">
                  <u>Términos y condiciones</u>
                </a>
              </label>
            </TermsContainer>
            <ContainerElements>
              <Button
                backgroundColor={primary}
                color={secondary}
                onClick={() => {
                  generateOrder()
                }}
                id={'MKTPL_PAY_NEXT'}
              >
                COMPRAR
              </Button>
            </ContainerElements>
            <ContainerTransaction>
              <label>Esta transacción se procesa con</label>
              <ContainerLink href="https://www.placetopay.com/web/home" target="_blank">
                <img src={p2pImage} />
              </ContainerLink>
            </ContainerTransaction>
            <ContainerElements>
              <NavButton
                to="/billing"
                backgroundColor={COLORS.teeth}
                color={primary}
                bordercolor={primary}
                third={'#000000'}
                onClick={() => {
                  onSetLoaded && onSetLoaded(false)
                }}
              >
                Atrás
              </NavButton>
            </ContainerElements>
          </Grid.Column>
        )}

        {isMobile && (
          <Grid.Column>
            <ContainerElements>
              <Collapsible trigger={<TitleSummaryResponsive iconName="down-open" />} triggerWhenOpen={<TitleSummaryResponsive iconName="up-open" />}>
                <ProductSummary />
              </Collapsible>
            </ContainerElements>
            <TermsContainer onClick={() => setTerms(!termsState)}>
              <Checkbox>{termsState == true ? <Icon color="black" size="11px" name="ok" className="kronos-icon" /> : <div />}</Checkbox>
              <label>
                Acepto{' '}
                <a target="_blank" href="https://grande-table.s3.amazonaws.com/tyc-grande-table-delivery.pdf">
                  <u>Términos y condiciones</u>
                </a>
              </label>
            </TermsContainer>
            <ContainerElements>
              <Button
                backgroundColor={primary}
                color={secondary}
                onClick={() => {
                  generateOrder()
                }}
                id={'MKTPL_PAY_NEXT'}
              >
                COMPRAR
              </Button>
            </ContainerElements>
            <ContainerTransaction style={{ textAlign: 'center' }}>
              <label>Esta transacción se procesa con</label>
              <ContainerLink href="https://www.placetopay.com/web/home" target="_blank">
                <img src={p2pImage} />
              </ContainerLink>
            </ContainerTransaction>
            <ContainerElements>
              <NavButton
                to="/billing"
                backgroundColor={COLORS.teeth}
                color={primary}
                bordercolor={primary}
                third={'#000000'}
                onClick={() => {
                  onSetLoaded && onSetLoaded(false)
                }}
              >
                Atrás
              </NavButton>
            </ContainerElements>
          </Grid.Column>
        )}
      </Grid.Row>
    </Checkout>
  ) : (
    <Checkout>
      <Grid.Row>
        <Loader />
      </Grid.Row>
    </Checkout>
  )
}

const AddTrackWithMutation = compose(graphql(GENERATE_ORDER_MUTATION), graphql(ADD_TRACKING_MUTATION))(withApollo(Payment))

export default AddTrackWithMutation
